.snowfall-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}

.snowflake {
    position: absolute;
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0.7;
    animation: fall linear infinite;
}

@keyframes fall {
    0% {
        transform: translateY(-100vh);
    }
    100% {
        transform: translateY(100vh);
    }
}
